import React, { FC, useState, useEffect, useMemo } from 'react';
import { useShopContext } from '../state/shop-context';
import { ShopifyProduct, Shop, ShopifyProductType } from 'superclient';
import ShopProductCard from '../components/shop/shop-product-card';
import Carousel from '../components/core/carousel';
import MetaModule from '../components/core/meta-module';
import { useQueryString } from '../state/use-query';
import { Link } from 'gatsby';

const TemplateShop: FC<{
  pageContext: { shop: Shop; products: ShopifyProduct[] };
}> = ({ pageContext }) => {
  const { shop, products } = pageContext;
  const [imageIndex, setImageIndex] = useState(0);
  const { productTypeFilter, setProductTypeFilter } = useShopContext();
  const [selectedProductCategory, setSelectedProductCategory] = useQueryString(
    'productType'
  );

  useEffect(() => {
    if (selectedProductCategory === 'prints') {
      setProductTypeFilter(ShopifyProductType.Prints);
    }
  }, []);

  const filteredProducts = useMemo(() => {
    return products.filter((product) => {
      if (productTypeFilter) {
        return product && product.productType === productTypeFilter;
      } else {
        return product.productType !== ShopifyProductType.Prints;
      }
    });
  }, [productTypeFilter, products]);

  const paramString = useMemo(() => {
    const paramString = `?q=${selectedProductCategory}`;
    return paramString;
  }, [filteredProducts, productTypeFilter]);

  useEffect(() => {
    setSelectedProductCategory(productTypeFilter);
  }, [filteredProducts]);

  const carouselItems = useMemo(() => {
    return shop.carouselItems
      ?.filter((item) => {
        const product = products.find(
          (product) => product.handle === item?.productSlug
        );
        if (!product) return false;
        if (productTypeFilter) {
          return product && product.productType === productTypeFilter;
        } else {
          return product.productType !== ShopifyProductType.Prints;
        }
      })
      ?.map((item, i) => {
        return {
          original: `${item.image.asset.url}`,
          thumbnail: '',
          _key: `${item[i]}`,
          title: `${item.title}`,
          productSlug: `${item?.productSlug}`
        };
      });
  }, [productTypeFilter, shop]);

  const updateIndex = (index: number) => {
    setImageIndex(index);
  };

  if (!carouselItems) return null;

  return (
    <div className="template__shop">
      <MetaModule title="Shop" path="/store" />
      <Carousel items={carouselItems} onIndexChange={updateIndex} />
      <div className="rel">
        <div className="featured__products bcg">
          <div className="featured__products_block container--xl ma z1 rel bcg px15 pb15 pt05">
            <div className={`featured__products-single x rel active`}>
              <Link
                className="abs x y top left"
                to={`/products/${carouselItems[imageIndex]?.productSlug}`}
              />
              <div className="f jcb fw ais featured__products-flex">
                <div className="x">
                  <h6 className="caps f aic">
                    New in the Shop
                    <span className="arrow__right arrow ml05 block cb rel" />
                  </h6>
                  <h3
                    className="p0 mb05 mt05 featured__products_header"
                    dangerouslySetInnerHTML={{
                      __html: carouselItems[imageIndex].title
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Layout after the Carousel */}
      <div className="container--xl ma p15 al">
        <div className="f jcs">
          {[
            { title: 'Products', value: undefined },
            {
              title: 'Photo Prints',
              value: ShopifyProductType.Prints
            }

            // Other filters to be added at a different time
            // {
            //   title: 'Apparel',
            //   value: ShopifyProductType.Apparel
            // },
            // {
            //   title: 'Patches',
            //   value: ShopifyProductType.Stickers
            // },
            // {
            //   title: 'Specialty Items',
            //   value: ShopifyProductType.Special
            // }
          ].map((category) => (
            <button
              key={category.value || category.title}
              className={`h6 caps pb0 px0 mr2 mb2 pointer ${
                productTypeFilter === category.value ? 'underline' : ''
              }`}
              onClick={() => setProductTypeFilter(category.value)}
            >
              {category.title}
            </button>
          ))}
        </div>
        <div className="f jcb aic fw">
          {filteredProducts.map((product) => (
            <ShopProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
      <div className="nav-push x" />
    </div>
  );
};

export default TemplateShop;
